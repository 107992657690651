/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Skriver
 * Skriver is your trusted companion for transcribing audio into text in a machine-friendly format.

# Features

- Transcribe audio files into text
- Join meetings and transcribe them
- Connect to calendars to join and transcribe meetings automatically

# Quick Start

## Step 1: Create a Skriver Account

Head to [auth.skriver.app](https://auth.skriver.app/signup) and register for a free account. You
will be redirected to [dash.skriver.app](https://dash.skriver.app).

## Step 2: Create your API Keys

Once logged int, click on your username and then "Organization API Keys".

> 🚧 Store the keys somewhere safe.
>
> Note that Skriver will make your API key available only once. Hence make sure to immediately copy
> and save the key in a safe and secure location.
>
> Your key is used to authenticate into our APIs and is hence highly sensitive. Please make sure to
> keep the keys in a secure environment. In the event of a compromise, you can immediately revoke
> key
> access from our dashboard and generate a new key.

## Step 3: Transcribe your first audio

You are now ready to query our APIs.

```curl
curl --request POST \
    --url 'https://api.skriver.app/v1/transcripts/remote' \
    --header 'Content-Type: application/json' \
    --header 'X-Api-Key: <API_KEY>' \
    --data '{
        "media_url": "https://assets.skriver.app/examples/interview_speech-analytics.wav",
        "level": "basic",
        "recorded_at": "2023-12-24T10:00:00.000Z",
        "metadata": { "foo": "bar" }
    }'
```

This endpoint will return a response like the following:

```json
{
    "id": "018e8550-9db8-764c-8c11-b8cd85727d9e",
    "level": "basic",
    "status": "queued",
    "monologues": null,
    "duration_in_seconds": null,
    "metadata": {
        "foo": "bar"
    },
    "speakers": null,
    "recorded_at": "2023-12-24T10:00:00Z",
    "created_at": "2024-03-28T13:47:17.838803Z"
}
```

Use the `id` to monitor the status of the transcription by calling the `GET` endpoint

```curl
curl --request GET \
     --url 'https://api.skriver.app/transcripts/018c00cf-076c-7c01-bcc1-dad2e8074bc8' \
     --header 'X-Api-Key: <API_KEY>'
```

You will get a response that contains the transcription text

```json
{
    "id": "018e8550-9db8-764c-8c11-b8cd85727d9e",
    "level": "basic",
    "status": "completed",
    "monologues": [
        {
            "start": 3.1999998,
            "end": 29.855,
            "speaker": 0,
            "text": "Some transcribed text here"
        }
    ],
    "duration_in_seconds": 33958.688,
    "metadata": {
        "foo": "bar"
    },
    "speakers": [
        "0"
    ],
    "recorded_at": "2023-12-24T10:00:00Z",
    "created_at": "2024-03-28T13:47:17.838803Z"
}
```

Congratulations! You have now converted an audio file into text.

## Step 4: Schedule a bot to join your meeting

You can now schedule a bot to join your meeting and transcribe it. Using the API key, you can create
a "bot"

```curl
curl --request GET \
    --url 'https://api.skriver.app/v1/bots/' \
    --header 'Content-Type: application/json' \
    --header 'X-Api-Key: <API_KEY>' \
    --data '{
        "bot_name": "C3-P0",
        "meeting_url": "https://meet.google.com/abc-defg-hij",
        "join_at": "2024-03-28T10:00:00Z",
        "metadata": { "foo": "bar" }
    }'
```

 * OpenAPI spec version: 0.3.1
 */

export * from './appPortalUrlResponse';
export * from './bot';
export * from './botId';
export * from './botMetadata';
export * from './botStatus';
export * from './botsFilter';
export * from './botsFilterMetadata';
export * from './createBotRequest';
export * from './createBotRequestMetadata';
export * from './createCalendarCodeResponse';
export * from './createCalendarCodeResponse2';
export * from './createCustomWordRequest';
export * from './customWord';
export * from './customWordId';
export * from './customWordsFilter';
export * from './getV1BotsParams';
export * from './getV1CustomWordsParams';
export * from './getV1GoogleCalendarEventsParams';
export * from './getV1GoogleCalendarsParams';
export * from './getV1MicrosoftCalendarEventsParams';
export * from './getV1MicrosoftCalendarsParams';
export * from './getV1TranscriptsParams';
export * from './googleCalendar';
export * from './googleCalendarEvent';
export * from './googleCalendarEventId';
export * from './googleCalendarEventsFilter';
export * from './googleCalendarId';
export * from './googleCalendarsFilter';
export * from './importTranscriptRequest';
export * from './importTranscriptRequestMetadata';
export * from './localTranscriptRequest';
export * from './localTranscriptRequestMetadata';
export * from './meResponse';
export * from './microsoftCalendar';
export * from './microsoftCalendarEvent';
export * from './microsoftCalendarEventId';
export * from './microsoftCalendarEventsFilter';
export * from './microsoftCalendarId';
export * from './microsoftCalendarsFilter';
export * from './organization';
export * from './paginatedVecForBot';
export * from './paginatedVecForCustomWord';
export * from './paginatedVecForGoogleCalendar';
export * from './paginatedVecForGoogleCalendarEvent';
export * from './paginatedVecForMicrosoftCalendar';
export * from './paginatedVecForMicrosoftCalendarEvent';
export * from './paginatedVecForTranscript';
export * from './pagination';
export * from './postV1TranscriptsLocalParams';
export * from './remoteTranscriptRequest';
export * from './remoteTranscriptRequestMetadata';
export * from './requestMetric';
export * from './speaker';
export * from './speakers';
export * from './transcript';
export * from './transcriptId';
export * from './transcriptMetadata';
export * from './transcriptMonologue';
export * from './transcriptMonologues';
export * from './transcriptStatus';
export * from './transcriptionLevel';
export * from './transcriptionMetric';
export * from './transcriptsFilter';
export * from './transcriptsFilterMetadata';
export * from './updateBotRequest';
export * from './updateBotRequestMetadata';
export * from './updateCustomWordRequest';
export * from './updateGoogleCalendarEventRequest';
export * from './updateSettingsRequest';
export * from './updateTranscriptRequest';
export * from './updateTranscriptRequestMetadata';
