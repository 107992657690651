import {Route, Routes} from 'react-router';
import {List} from './List';
import {View} from './View';

export function Bots() {
  return (
    <Routes>
      <Route path="" element={<List />} />
      <Route path=":botId" element={<View />} />
    </Routes>
  );
}
