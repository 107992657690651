/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Skriver
 * Skriver is your trusted companion for transcribing audio into text in a machine-friendly format.

# Features

- Transcribe audio files into text
- Join meetings and transcribe them
- Connect to calendars to join and transcribe meetings automatically

# Quick Start

## Step 1: Create a Skriver Account

Head to [auth.skriver.app](https://auth.skriver.app/signup) and register for a free account. You
will be redirected to [dash.skriver.app](https://dash.skriver.app).

## Step 2: Create your API Keys

Once logged int, click on your username and then "Organization API Keys".

> 🚧 Store the keys somewhere safe.
>
> Note that Skriver will make your API key available only once. Hence make sure to immediately copy
> and save the key in a safe and secure location.
>
> Your key is used to authenticate into our APIs and is hence highly sensitive. Please make sure to
> keep the keys in a secure environment. In the event of a compromise, you can immediately revoke
> key
> access from our dashboard and generate a new key.

## Step 3: Transcribe your first audio

You are now ready to query our APIs.

```curl
curl --request POST \
    --url 'https://api.skriver.app/v1/transcripts/remote' \
    --header 'Content-Type: application/json' \
    --header 'X-Api-Key: <API_KEY>' \
    --data '{
        "media_url": "https://assets.skriver.app/examples/interview_speech-analytics.wav",
        "level": "basic",
        "recorded_at": "2023-12-24T10:00:00.000Z",
        "metadata": { "foo": "bar" }
    }'
```

This endpoint will return a response like the following:

```json
{
    "id": "018e8550-9db8-764c-8c11-b8cd85727d9e",
    "level": "basic",
    "status": "queued",
    "monologues": null,
    "duration_in_seconds": null,
    "metadata": {
        "foo": "bar"
    },
    "speakers": null,
    "recorded_at": "2023-12-24T10:00:00Z",
    "created_at": "2024-03-28T13:47:17.838803Z"
}
```

Use the `id` to monitor the status of the transcription by calling the `GET` endpoint

```curl
curl --request GET \
     --url 'https://api.skriver.app/transcripts/018c00cf-076c-7c01-bcc1-dad2e8074bc8' \
     --header 'X-Api-Key: <API_KEY>'
```

You will get a response that contains the transcription text

```json
{
    "id": "018e8550-9db8-764c-8c11-b8cd85727d9e",
    "level": "basic",
    "status": "completed",
    "monologues": [
        {
            "start": 3.1999998,
            "end": 29.855,
            "speaker": 0,
            "text": "Some transcribed text here"
        }
    ],
    "duration_in_seconds": 33958.688,
    "metadata": {
        "foo": "bar"
    },
    "speakers": [
        "0"
    ],
    "recorded_at": "2023-12-24T10:00:00Z",
    "created_at": "2024-03-28T13:47:17.838803Z"
}
```

Congratulations! You have now converted an audio file into text.

## Step 4: Schedule a bot to join your meeting

You can now schedule a bot to join your meeting and transcribe it. Using the API key, you can create
a "bot"

```curl
curl --request GET \
    --url 'https://api.skriver.app/v1/bots/' \
    --header 'Content-Type: application/json' \
    --header 'X-Api-Key: <API_KEY>' \
    --data '{
        "bot_name": "C3-P0",
        "meeting_url": "https://meet.google.com/abc-defg-hij",
        "join_at": "2024-03-28T10:00:00Z",
        "metadata": { "foo": "bar" }
    }'
```

 * OpenAPI spec version: 0.3.1
 */
import {
  z as zod
} from 'zod';


/**
 * @summary List all bots
 */
export const getV1BotsQueryCreatedAfterDefault = new Date("undefined");export const getV1BotsQueryCreatedBeforeDefault = new Date("undefined");export const getV1BotsQueryMetadataDefault = null;export const getV1BotsQueryAfterDefault = null;export const getV1BotsQueryBeforeDefault = null;export const getV1BotsQueryPerPageDefault = 10;
export const getV1BotsQueryPerPageMax = 100;


export const getV1BotsQueryParams = zod.object({
  "created_after": zod.date().nullish().describe('Only include bots created after this date'),
  "created_before": zod.date().nullish().describe('Only include bots created before this date'),
  "metadata": zod.any().nullish().describe('Only include bots with this metadata.\n\nThis is a JSON object with string keys and string or number values.\n\nExample: `metadata[key]=value&metadata[key2]=123`'),
  "after": zod.string().uuid().nullish(),
  "before": zod.string().uuid().nullish(),
  "per_page": zod.number().min(1).max(getV1BotsQueryPerPageMax).default(getV1BotsQueryPerPageDefault).describe('The number of results to return per page')
})

export const getV1Bots200ResponseItem = zod.object({
  "bot_name": zod.string().describe('The name of the bot that will be displayed in the call.'),
  "created_at": zod.date().describe('Timestamp of when the bot was created'),
  "id": zod.string().uuid().describe('Unique identifier of the bot'),
  "join_at": zod.date().nullish().describe('The time at which the bot will join the call, formatted in ISO 8601. This field can only be read from scheduled bots that have not yet joined a call. Once a bot has joined a call, it\'s join_at will be cleared.'),
  "meeting_url": zod.string().describe('The url of the meeting. For example, https://zoom.us/j/123?pwd=456. This field will be cleared a few days after the bot has joined a call.'),
  "metadata": zod.any().nullish().describe('Arbitrary metadata'),
  "status": zod.enum(['queued', 'scheduled', 'joining_call', 'in_call', 'completed', 'failed', 'canceled'])
}).describe('A bot joins a meeting and records the conversation.')
export const getV1Bots200Response = zod.array(getV1Bots200ResponseItem)

/**
 * @summary Create a new bot
 */
export const postV1BotsBodyMetadataDefault = null;

export const postV1BotsBody = zod.object({
  "bot_name": zod.string().describe('The name of the bot that will be displayed in the call.'),
  "join_at": zod.date().nullish().describe('The time at which the bot will join the call, formatted in ISO 8601. This field can only be read from scheduled bots that have not yet joined a call. Once a bot has joined a call, it\'s join_at will be cleared.'),
  "meeting_url": zod.string().url().describe('The url of the meeting. For example, https://zoom.us/j/123?pwd=456. This field will be cleared a few days after the bot has joined a call.'),
  "metadata": zod.any().nullish().describe('Metadata of the bot. It will be included in the transcript metadata.\n\nExample: `{ \"key1\": \"value1\", \"key2\": \"value2\" }`')
})

export const postV1Bots200Response = zod.object({
  "bot_name": zod.string().describe('The name of the bot that will be displayed in the call.'),
  "created_at": zod.date().describe('Timestamp of when the bot was created'),
  "id": zod.string().uuid().describe('Unique identifier of the bot'),
  "join_at": zod.date().nullish().describe('The time at which the bot will join the call, formatted in ISO 8601. This field can only be read from scheduled bots that have not yet joined a call. Once a bot has joined a call, it\'s join_at will be cleared.'),
  "meeting_url": zod.string().describe('The url of the meeting. For example, https://zoom.us/j/123?pwd=456. This field will be cleared a few days after the bot has joined a call.'),
  "metadata": zod.any().nullish().describe('Arbitrary metadata'),
  "status": zod.enum(['queued', 'scheduled', 'joining_call', 'in_call', 'completed', 'failed', 'canceled'])
}).describe('A bot joins a meeting and records the conversation.')

/**
 * @summary Get a single bot
 */
export const getV1BotsIdParams = zod.object({
  "id": zod.string().uuid().describe('The ID of the bot.')
})

export const getV1BotsId200Response = zod.object({
  "bot_name": zod.string().describe('The name of the bot that will be displayed in the call.'),
  "created_at": zod.date().describe('Timestamp of when the bot was created'),
  "id": zod.string().uuid().describe('Unique identifier of the bot'),
  "join_at": zod.date().nullish().describe('The time at which the bot will join the call, formatted in ISO 8601. This field can only be read from scheduled bots that have not yet joined a call. Once a bot has joined a call, it\'s join_at will be cleared.'),
  "meeting_url": zod.string().describe('The url of the meeting. For example, https://zoom.us/j/123?pwd=456. This field will be cleared a few days after the bot has joined a call.'),
  "metadata": zod.any().nullish().describe('Arbitrary metadata'),
  "status": zod.enum(['queued', 'scheduled', 'joining_call', 'in_call', 'completed', 'failed', 'canceled'])
}).describe('A bot joins a meeting and records the conversation.')

/**
 * @summary Update a bot
 */
export const putV1BotsIdParams = zod.object({
  "id": zod.string().uuid().describe('The ID of the bot.')
})

export const putV1BotsIdBodyMetadataDefault = null;

export const putV1BotsIdBody = zod.object({
  "bot_name": zod.string().describe('The name of the bot that will be displayed in the call.'),
  "join_at": zod.date().nullish().describe('The time at which the bot will join the call, formatted in ISO 8601. This field can only be read from scheduled bots that have not yet joined a call. Once a bot has joined a call, it\'s join_at will be cleared.'),
  "meeting_url": zod.string().url().describe('The url of the meeting. For example, https://zoom.us/j/123?pwd=456. This field will be cleared a few days after the bot has joined a call.'),
  "metadata": zod.any().nullish().describe('Metadata of the bot. It will be included in the transcript metadata.\n\nExample: `{ \"key1\": \"value1\", \"key2\": \"value2\" }`')
})

export const putV1BotsId200Response = zod.object({
  "bot_name": zod.string().describe('The name of the bot that will be displayed in the call.'),
  "created_at": zod.date().describe('Timestamp of when the bot was created'),
  "id": zod.string().uuid().describe('Unique identifier of the bot'),
  "join_at": zod.date().nullish().describe('The time at which the bot will join the call, formatted in ISO 8601. This field can only be read from scheduled bots that have not yet joined a call. Once a bot has joined a call, it\'s join_at will be cleared.'),
  "meeting_url": zod.string().describe('The url of the meeting. For example, https://zoom.us/j/123?pwd=456. This field will be cleared a few days after the bot has joined a call.'),
  "metadata": zod.any().nullish().describe('Arbitrary metadata'),
  "status": zod.enum(['queued', 'scheduled', 'joining_call', 'in_call', 'completed', 'failed', 'canceled'])
}).describe('A bot joins a meeting and records the conversation.')

/**
 * @summary Delete a bot
 */
export const deleteV1BotsIdParams = zod.object({
  "id": zod.string().uuid().describe('The ID of the bot.')
})

export const deleteV1BotsId200Response = zod.object({
  "bot_name": zod.string().describe('The name of the bot that will be displayed in the call.'),
  "created_at": zod.date().describe('Timestamp of when the bot was created'),
  "id": zod.string().uuid().describe('Unique identifier of the bot'),
  "join_at": zod.date().nullish().describe('The time at which the bot will join the call, formatted in ISO 8601. This field can only be read from scheduled bots that have not yet joined a call. Once a bot has joined a call, it\'s join_at will be cleared.'),
  "meeting_url": zod.string().describe('The url of the meeting. For example, https://zoom.us/j/123?pwd=456. This field will be cleared a few days after the bot has joined a call.'),
  "metadata": zod.any().nullish().describe('Arbitrary metadata'),
  "status": zod.enum(['queued', 'scheduled', 'joining_call', 'in_call', 'completed', 'failed', 'canceled'])
}).describe('A bot joins a meeting and records the conversation.')

