import {Button, Divider, Flex, LoadingOverlay, Modal, Table, Title, Tooltip} from '@mantine/core';
import type {FormErrors} from '@mantine/form/lib/types';
import {useDisclosure} from '@mantine/hooks';
import {notifications} from '@mantine/notifications';
import {IconPlus} from '@tabler/icons-react';
import {useQueryClient} from '@tanstack/react-query';
import {useCallback} from 'react';
import {type CustomWord, type PaginatedVecForCustomWord, useGetV1CustomWords} from '../../api';
import type {ApiResponse} from '../../api/mutator/custom-instance';
import {Form} from './Form';
import {Row} from './Row';

export function List() {
  const [opened, { open, close }] = useDisclosure(false);

  const queryClient = useQueryClient();
  const customWords = useGetV1CustomWords();

  const onCreateSuccess = useCallback(
    (apiResponse: ApiResponse<CustomWord>) => {
      queryClient.setQueryData<ApiResponse<PaginatedVecForCustomWord>>(
        customWords.queryKey,
        (input) => {
          if (input) {
            return {
              headers: input.headers,
              body: {
                ...input.body,
                items: [...input.body.items, apiResponse.body],
              },
            };
          }
        },
      );
      notifications.show({
        title: 'Success',
        message: 'Successfully created custom word',
        color: 'green',
        withCloseButton: true,
      });
      close();
    },
    [customWords.queryKey, queryClient.setQueryData, close],
  );

  const onCreateError = useCallback((error: FormErrors) => {
    console.error(error);
  }, []);

  const onDeleteSuccess = useCallback(
    (_apiResponse: ApiResponse<CustomWord>, variables: { id: string }) => {
      queryClient.setQueryData<ApiResponse<PaginatedVecForCustomWord>>(
        customWords.queryKey,
        (input) => {
          if (input) {
            return {
              headers: input.headers,
              body: { ...input.body, items: input.body.items.filter((i) => i.id !== variables.id) },
            };
          }
        },
      );
    },
    [customWords.queryKey, queryClient.setQueryData],
  );

  const onDeleteError = useCallback((error: FormErrors) => {
    console.error(error);
  }, []);

  const onUpdateSuccess = useCallback(
    (apiResponse: ApiResponse<CustomWord>) => {
      queryClient.setQueryData<ApiResponse<PaginatedVecForCustomWord>>(
        customWords.queryKey,
        (input) => {
          if (input) {
            return {
              headers: input.headers,
              body: {
                ...input.body,
                items: input.body.items.map((i) => {
                  if (i.id === apiResponse.body.id) {
                    return apiResponse.body;
                  }

                  return i;
                }),
              },
            };
          }
        },
      );
    },
    [customWords.queryKey, queryClient.setQueryData],
  );

  const onUpdateError = useCallback((error: FormErrors) => {
    console.error(error);
  }, []);

  if (customWords.isLoading) return <LoadingOverlay visible />;
  if (customWords.isError) return <h1>{customWords.error?.message}</h1>;
  if (!customWords.isSuccess) return <LoadingOverlay visible />;

  return (
    <>
      <Flex justify="space-between">
        <Title>Custom Words</Title>
        <Tooltip label="Create Custom Word">
          <Button onClick={open}>
            <IconPlus />
          </Button>
        </Tooltip>
      </Flex>
      <Divider mb="md" />

      <Modal opened={opened} onClose={close} title="Create Bot">
        <Form onSuccess={onCreateSuccess} onError={onCreateError} />
      </Modal>

      <Table.ScrollContainer minWidth={800}>
        <Table verticalSpacing="xs">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Text</Table.Th>
              <Table.Th>Enabled</Table.Th>
              <Table.Th> </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {customWords.data.body.items.map((customWord) => (
              <Row
                key={customWord.id}
                customWord={customWord}
                onDeleteError={onDeleteError}
                onDeleteSuccess={onDeleteSuccess}
                onUpdateSuccess={onUpdateSuccess}
                onUpdateError={onUpdateError}
              />
            ))}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
}
