import {Button, TextInput} from '@mantine/core';
import {DateTimePicker} from '@mantine/dates';
import {useForm, zodResolver} from '@mantine/form';
import type {FormErrors} from '@mantine/form/lib/types';
import {useCallback, useMemo} from 'react';
import {type Bot, type CreateBotRequest, usePostV1Bots} from '../../api';
import {postV1BotsBody} from '../../api/endpoints/bots/bots.zod';
import type {ApiResponse} from '../../api/mutator/custom-instance';

interface Props {
  onSuccess: (
    data: ApiResponse<Bot>,
    variables: { data: CreateBotRequest },
    context: unknown,
  ) => unknown;
  onError: (error: FormErrors, variables: { data: CreateBotRequest }, context: unknown) => unknown;
}

export function Form({ onSuccess, onError }: Readonly<Props>) {
  const botMutation = usePostV1Bots({
    mutation: { onSuccess, onError },
  });

  const handleSubmit = useCallback(
    (values: CreateBotRequest) => botMutation.mutate({ data: values }),
    [botMutation.mutate],
  );

  const defaultValues = useMemo<CreateBotRequest>(
    () => ({
      meeting_url: '',
      bot_name: '',
      join_at: new Date(),
    }),
    [],
  );

  const form = useForm<CreateBotRequest>({
    initialValues: defaultValues,
    validate: zodResolver(postV1BotsBody),
    validateInputOnBlur: true,
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit, console.error)}>
      <TextInput
        autoFocus
        mb="md"
        label="Meeting URL"
        description="The url of the meeting. For example, https://zoom.us/j/123?pwd=456."
        type="url"
        placeholder="https://zoom.us/123"
        required
        {...form.getInputProps('meeting_url')}
      />
      <TextInput
        mb="md"
        label="Bot Name"
        description="Name of the bot"
        placeholder="My Assistant"
        required
        {...form.getInputProps('bot_name')}
      />
      <DateTimePicker
        mb="md"
        label="Join At"
        description="When the bot should join the meeting. Leave empty to join immediately."
        placeholder="Join At"
        clearable
        minDate={new Date()}
        {...form.getInputProps('join_at')}
      />

      <Button fullWidth type="submit">
        Submit
      </Button>
    </form>
  );
}
