/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Skriver
 * Skriver is your trusted companion for transcribing audio into text in a machine-friendly format.

# Features

- Transcribe audio files into text
- Join meetings and transcribe them
- Connect to calendars to join and transcribe meetings automatically

# Quick Start

## Step 1: Create a Skriver Account

Head to [auth.skriver.app](https://auth.skriver.app/signup) and register for a free account. You
will be redirected to [dash.skriver.app](https://dash.skriver.app).

## Step 2: Create your API Keys

Once logged int, click on your username and then "Organization API Keys".

> 🚧 Store the keys somewhere safe.
>
> Note that Skriver will make your API key available only once. Hence make sure to immediately copy
> and save the key in a safe and secure location.
>
> Your key is used to authenticate into our APIs and is hence highly sensitive. Please make sure to
> keep the keys in a secure environment. In the event of a compromise, you can immediately revoke
> key
> access from our dashboard and generate a new key.

## Step 3: Transcribe your first audio

You are now ready to query our APIs.

```curl
curl --request POST \
    --url 'https://api.skriver.app/v1/transcripts/remote' \
    --header 'Content-Type: application/json' \
    --header 'X-Api-Key: <API_KEY>' \
    --data '{
        "media_url": "https://assets.skriver.app/examples/interview_speech-analytics.wav",
        "level": "basic",
        "recorded_at": "2023-12-24T10:00:00.000Z",
        "metadata": { "foo": "bar" }
    }'
```

This endpoint will return a response like the following:

```json
{
    "id": "018e8550-9db8-764c-8c11-b8cd85727d9e",
    "level": "basic",
    "status": "queued",
    "monologues": null,
    "duration_in_seconds": null,
    "metadata": {
        "foo": "bar"
    },
    "speakers": null,
    "recorded_at": "2023-12-24T10:00:00Z",
    "created_at": "2024-03-28T13:47:17.838803Z"
}
```

Use the `id` to monitor the status of the transcription by calling the `GET` endpoint

```curl
curl --request GET \
     --url 'https://api.skriver.app/transcripts/018c00cf-076c-7c01-bcc1-dad2e8074bc8' \
     --header 'X-Api-Key: <API_KEY>'
```

You will get a response that contains the transcription text

```json
{
    "id": "018e8550-9db8-764c-8c11-b8cd85727d9e",
    "level": "basic",
    "status": "completed",
    "monologues": [
        {
            "start": 3.1999998,
            "end": 29.855,
            "speaker": 0,
            "text": "Some transcribed text here"
        }
    ],
    "duration_in_seconds": 33958.688,
    "metadata": {
        "foo": "bar"
    },
    "speakers": [
        "0"
    ],
    "recorded_at": "2023-12-24T10:00:00Z",
    "created_at": "2024-03-28T13:47:17.838803Z"
}
```

Congratulations! You have now converted an audio file into text.

## Step 4: Schedule a bot to join your meeting

You can now schedule a bot to join your meeting and transcribe it. Using the API key, you can create
a "bot"

```curl
curl --request GET \
    --url 'https://api.skriver.app/v1/bots/' \
    --header 'Content-Type: application/json' \
    --header 'X-Api-Key: <API_KEY>' \
    --data '{
        "bot_name": "C3-P0",
        "meeting_url": "https://meet.google.com/abc-defg-hij",
        "join_at": "2024-03-28T10:00:00Z",
        "metadata": { "foo": "bar" }
    }'
```

 * OpenAPI spec version: 0.3.1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { useCallback } from 'react';

import type { MeResponse } from '../../models';

import { useCustomInstance } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';

/**
 * @summary Get the current org
 */
export const useGetV1MeHook = () => {
  const getV1Me = useCustomInstance<MeResponse>();

  return useCallback(
    (signal?: AbortSignal) => {
      return getV1Me({ url: `/v1/me`, method: 'GET', signal });
    },
    [getV1Me],
  );
};

export const getGetV1MeQueryKey = () => {
  return [`/v1/me`] as const;
};

export const useGetV1MeQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetV1MeQueryKey();

  const getV1Me = useGetV1MeHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>> = ({
    signal,
  }) => getV1Me(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetV1MeQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>
>;
export type GetV1MeQueryError = ErrorType<unknown>;

export function useGetV1Me<
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>,
        TError,
        Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetV1Me<
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>,
        TError,
        Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
export function useGetV1Me<
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };
/**
 * @summary Get the current org
 */

export function useGetV1Me<
  TData = Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetV1MeHook>>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {
  const queryOptions = useGetV1MeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
