import {Button, Divider, Flex, LoadingOverlay, Modal, Table, Title, Tooltip} from '@mantine/core';
import type {FormErrors} from '@mantine/form/lib/types';
import {useDisclosure} from '@mantine/hooks';
import {notifications} from '@mantine/notifications';
import {IconPlus} from '@tabler/icons-react';
import {useQueryClient} from '@tanstack/react-query';
import {useCallback} from 'react';
import {type Bot, type PaginatedVecForBot, useGetV1Bots} from '../../api';
import type {ApiResponse} from '../../api/mutator/custom-instance';
import {Form} from './Form';
import {Row} from './Row';

export function List() {
  const [opened, { open, close }] = useDisclosure(false);

  const queryClient = useQueryClient();
  const bots = useGetV1Bots();

  const onCreateSuccess = useCallback(
    (apiResponse: ApiResponse<Bot>) => {
      queryClient.setQueryData<ApiResponse<PaginatedVecForBot>>(bots.queryKey, (oldData) => {
        if (oldData) {
          return {
            headers: oldData.headers,
            body: {
              ...oldData.body,
              items: [...oldData.body.items, apiResponse.body],
            },
          };
        }
      });
      notifications.show({
        title: 'Success',
        message: 'Successfully created bot',
        color: 'green',
        withCloseButton: true,
      });
      close();
    },
    [bots.queryKey, queryClient.setQueryData, close],
  );

  const onCreateError = useCallback((error: FormErrors) => {
    console.error(error);
  }, []);

  const onDeleteSuccess = useCallback(
    (_data: unknown, variables: { id: string }) => {
      queryClient.setQueryData<ApiResponse<PaginatedVecForBot>>(bots.queryKey, (oldData) => {
        if (oldData) {
          return {
            headers: oldData.headers,
            body: {
              ...oldData.body,
              items: oldData.body.items.filter((i) => i.id !== variables.id),
            },
          };
        }
      });
    },
    [bots.queryKey, queryClient.setQueryData],
  );

  const onDeleteError = useCallback((error: FormErrors) => {
    console.error(error);
  }, []);

  if (bots.isLoading) return <LoadingOverlay visible />;
  if (bots.isError) return <h1>{bots.error?.message}</h1>;
  if (!bots.isSuccess) return <LoadingOverlay visible />;

  return (
    <>
      <Flex justify="space-between">
        <Title>Bots</Title>
        <Tooltip label="Create Bot">
          <Button onClick={open}>
            <IconPlus />
          </Button>
        </Tooltip>
      </Flex>
      <Divider mb="md" />

      <Modal opened={opened} onClose={close} title="Create Bot">
        <Form onSuccess={onCreateSuccess} onError={onCreateError} />
      </Modal>

      <Table.ScrollContainer minWidth={800}>
        <Table verticalSpacing="xs">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Status</Table.Th>
              <Table.Th>Transcript</Table.Th>
              <Table.Th>Join At</Table.Th>
              <Table.Th> </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {bots.data.body.items.map((bot) => (
              <Row
                key={bot.id}
                bot={bot}
                onDeleteError={onDeleteError}
                onDeleteSuccess={onDeleteSuccess}
              />
            ))}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
}
