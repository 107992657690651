import {Anchor, Flex, Grid, LoadingOverlay, Paper, Text, useMantineColorScheme,} from '@mantine/core';
import {IconLink} from '@tabler/icons-react';
import ColorHash from 'color-hash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {Link, useParams} from 'react-router';
import {useGetV1TranscriptsId} from '../../api';

dayjs.extend(utc);

export function View() {
  const { transcriptId } = useParams();
  const theme = useMantineColorScheme();

  const { data, error, isLoading } = useGetV1TranscriptsId(String(transcriptId), {
    query: { enabled: Boolean(transcriptId) },
  });

  if (isLoading || !data) {
    return <LoadingOverlay visible={true} />;
  }

  if (error) {
    return <h1>{error.message}</h1>;
  }

  let lightness = 0.3;
  if (theme.colorScheme === 'dark') {
    lightness = 0.7;
  }
  const colorHash = new ColorHash({ lightness });

  const {
    bot_id,
    created_at,
    duration_in_seconds,
    level,
    metadata,
    monologues,
    recorded_at,
    speakers,
    status,
  } = data.body;
  return (
    <Grid gutter="md">
      <Grid.Col span={8}>
        {!monologues ? (
          <Paper p="md" mb="md" radius="sm">
            <Text>No monologues found</Text>
          </Paper>
        ) : (
          monologues.map((monologue) => {
            const color = colorHash.hex(monologue.speaker_id);
            const start = dayjs.utc(monologue.start * 1_000).format('HH:mm:ss');
            const end = dayjs.utc(monologue.end * 1_000).format('HH:mm:ss');
            const speaker = speakers?.find((speaker) => speaker.id === monologue.speaker_id) ?? {
              id: monologue.speaker_id,
              name: 'Unknown',
            };

            return (
              <Paper key={speaker.id} p="md" mb="md" radius="sm">
                <Flex justify="space-between" c={color}>
                  <Text mb="sm">
                    {speaker.name} ({speaker.id})
                  </Text>
                  <Text mb="sm">
                    {start} - {end}
                  </Text>
                </Flex>
                <Text>{monologue.text}</Text>
              </Paper>
            );
          })
        )}
      </Grid.Col>
      <Grid.Col span={4}>
        <Paper p="lg" radius="ms">
          <Text mb="sm">
            <strong>Level</strong>: {level}
          </Text>
          <Text mb="sm">
            <strong>Status</strong>: {status}
          </Text>
          <Text mb="sm">
            <strong>Duration</strong>: {duration_in_seconds}s
          </Text>
          <Text mb="sm">
            <strong>Date</strong>: {new Date(recorded_at ?? created_at).toLocaleString()}
          </Text>
          {speakers && (
            <Text>
              <strong>Speakers</strong>:
              <ul>
                {speakers.map((speaker) => (
                  <li key={speaker.id}>
                    {speaker.id}: {speaker.name}
                  </li>
                ))}
              </ul>
            </Text>
          )}
          {Boolean(metadata) && (
            <Text>
              <strong>Metadata</strong>:
              <ul>
                {Object.entries(metadata as Record<string, unknown>).map(([key, value]) => (
                  <li key={key}>
                    {key}: {value as string}
                  </li>
                ))}
              </ul>
            </Text>
          )}
          {bot_id && (
            <Text>
              <strong>Bot</strong>:
              <Anchor component={Link} to={`/bots/${bot_id}`}>
                <IconLink />
              </Anchor>
            </Text>
          )}
        </Paper>
      </Grid.Col>
    </Grid>
  );
}
