import {Anchor, Button, Flex, Table, Tooltip} from '@mantine/core';
import type {FormErrors} from '@mantine/form/lib/types';
import {IconEye, IconRobot, IconTrash} from '@tabler/icons-react';
import dayjs from 'dayjs';
import {useCallback} from 'react';
import {Link} from 'react-router';
import {type Transcript, useDeleteV1TranscriptsId} from '../../api';

interface Props {
  transcript: Transcript;
  onDeleteSuccess: (data: unknown, variables: { id: string }, context: unknown) => unknown;
  onDeleteError?: (error: FormErrors, variables: { id: string }, context: unknown) => unknown;
}

export function Row({ transcript, onDeleteSuccess, onDeleteError }: Props) {
  const deleteTranscript = useDeleteV1TranscriptsId({
    mutation: { onSuccess: onDeleteSuccess, onError: onDeleteError },
  });
  const onDelete = useCallback(
    () => deleteTranscript.mutate({ id: transcript.id }),
    [transcript, deleteTranscript],
  );

  return (
    <Table.Tr key={transcript.id}>
      <Table.Td>{transcript.status}</Table.Td>
      <Table.Td>{transcript.level}</Table.Td>
      <Table.Td>
        {typeof transcript.duration_in_seconds === 'number'
          ? dayjs.utc(transcript.duration_in_seconds * 1_000).format('HH:mm:ss')
          : '-'}
      </Table.Td>
      <Table.Td>
        {transcript.bot_id ? (
          <Anchor component={Link} to={`/bots/${transcript.bot_id}`}>
            <IconRobot />
          </Anchor>
        ) : (
          '-'
        )}
      </Table.Td>
      <Table.Td>
        {dayjs(transcript.recorded_at ?? transcript.created_at).format('D MMM, YYYY HH:mm:ss')}
      </Table.Td>
      <Table.Td>
        <Flex align="center">
          <Tooltip label="View Transcript">
            <Anchor component={Link} to={transcript.id}>
              <Button variant="outline" size="xs">
                <IconEye />
              </Button>
            </Anchor>
          </Tooltip>
          &nbsp;
          <Tooltip label="Delete Transcript">
            <Button onClick={onDelete} variant="outline" color="red" size="xs">
              <IconTrash />
            </Button>
          </Tooltip>
        </Flex>
      </Table.Td>
    </Table.Tr>
  );
}
