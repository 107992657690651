import {Accordion, Anchor, Button, Checkbox, Group, LoadingOverlay, Select, Table, Text,} from '@mantine/core';
import {IconBrandGoogle, IconBrandWindows} from '@tabler/icons-react';
import {type QueryKey, useQueryClient} from '@tanstack/react-query';
import type React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {Link} from 'react-router';
import {
  type GoogleCalendarEvent,
  type GoogleCalendarEventId,
  type PaginatedVecForGoogleCalendarEvent,
  useDeleteV1GoogleCalendarsId,
  useGetV1GoogleCalendarEvents,
  useGetV1GoogleCalendars,
  useGetV1MicrosoftCalendarEvents,
  useGetV1MicrosoftCalendars,
  usePutV1GoogleCalendarEventsId,
} from '../../api';
import type {ApiResponse} from '../../api/mutator/custom-instance';

export function List() {
  const googleCalendars = useGetV1GoogleCalendars();
  const microsoftCalendars = useGetV1MicrosoftCalendars();

  if (googleCalendars.isLoading || microsoftCalendars.isLoading) return <LoadingOverlay visible />;
  if (googleCalendars.isError || microsoftCalendars.isError)
    return (
      <h1>
        {googleCalendars.error?.message}
        {microsoftCalendars.error?.message}
      </h1>
    );
  if (!googleCalendars.isSuccess || !microsoftCalendars.isSuccess) return <h1>Not ready</h1>;

  return (
    <Accordion>
      {googleCalendars.data.body.items.map((googleCalendar) => (
        <Accordion.Item key={googleCalendar.id} value={googleCalendar.id}>
          <Accordion.Control icon={<IconBrandGoogle />}>{googleCalendar.name}</Accordion.Control>
          <Accordion.Panel>
            <GoogleCalendarEvents id={googleCalendar.id} />
          </Accordion.Panel>
        </Accordion.Item>
      ))}
      {microsoftCalendars.data.body.items.map((microsoftCalendar) => (
        <Accordion.Item key={microsoftCalendar.id} value={microsoftCalendar.id}>
          <Accordion.Control icon={<IconBrandWindows />}>
            {microsoftCalendar.name}
          </Accordion.Control>
          <Accordion.Panel>
            <MicrosoftCalendarEvents id={microsoftCalendar.id} />
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

function GoogleCalendarEvents({ id }: Readonly<{ id: string }>) {
  const now = useMemo(() => new Date(), []);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();

  const events = useGetV1GoogleCalendarEvents({
    google_calendar_id: id,
    start_after: new Date(now.getTime() - 3 * 24 * 60 * 60 * 1000).toISOString(),
    start_before: new Date(now.getTime() + 90 * 24 * 60 * 60 * 1000).toISOString(),
    per_page: perPage,
  });
  const onDeleteSuccess = useCallback(
    (_data: unknown, variables: GoogleCalendarEventId) => {
      queryClient.setQueryData(
        events.queryKey,
        ({ body, headers }: ApiResponse<PaginatedVecForGoogleCalendarEvent>) => ({
          headers,
          body: { ...body, items: body.items.filter((i) => i.id !== variables.id) },
        }),
      );
    },
    [events.queryKey],
  );
  const deleteV1GoogleCalendarsId = useDeleteV1GoogleCalendarsId({
    mutation: { onSuccess: onDeleteSuccess },
  });

  if (events.isLoading) return <LoadingOverlay visible />;
  if (events.isError) return <h1>{events.error?.message}</h1>;
  if (!events.isSuccess) return <LoadingOverlay visible />;

  return (
    <div>
      <Group>
        <Button variant="subtle" onClick={() => deleteV1GoogleCalendarsId.mutate({ id }, {})}>
          Disconnect
        </Button>
        <Select
          placeholder="Pick value"
          data={[
            {
              value: '10',
              label: '10 per page',
            },
            {
              value: '25',
              label: '25 per page',
            },
            {
              value: '50',
              label: '50 per page',
            },
          ]}
          onChange={(value) => {
            if (value !== null) {
              setPerPage(Number.parseInt(value));
            }
          }}
          value={perPage.toString()}
          allowDeselect={false}
        />
        <Button.Group>
          <Button
            variant="default"
            onClick={() =>
              setPage((currentPage) => (currentPage > 1 ? currentPage - 1 : currentPage))
            }
          >
            Previous
          </Button>
          <Button variant="default" onClick={() => setPage(1)}>
            {page}
          </Button>
          <Button variant="default" onClick={() => setPage((currentPage) => currentPage + 1)}>
            Next
          </Button>
        </Button.Group>
      </Group>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Title</Table.Th>
            <Table.Th>Meeting</Table.Th>
            <Table.Th>Date & Time</Table.Th>
            <Table.Th>Add Skriver</Table.Th>
            <Table.Th>Bot</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {events.data.body.items.map((event) => (
            <GoogleCalendarEventRow key={event.id} event={event} queryKey={events.queryKey} />
          ))}
        </Table.Tbody>
      </Table>
    </div>
  );
}

function GoogleCalendarEventRow({
  event,
  queryKey,
}: Readonly<{
  event: GoogleCalendarEvent;
  queryKey: QueryKey;
}>) {
  const queryClient = useQueryClient();

  const updateEvent = usePutV1GoogleCalendarEventsId({
    mutation: {
      onSettled: () => queryClient.invalidateQueries({ queryKey }),
    },
  });

  const onToggle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateEvent.mutate({
        id: event.id,
        data: { should_join_meeting: e.target.checked },
      });
    },
    [updateEvent, event.id, event.should_join_meeting],
  );

  return (
    <Table.Tr>
      <Table.Td>{event.title}</Table.Td>
      <Table.Td>{event.meeting_url}</Table.Td>
      <Table.Td>
        <Text size="lg">
          {new Date(event.start_at).toLocaleDateString('en', {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
          })}
        </Text>
        <Text size="xs">
          {new Date(event.start_at).toLocaleTimeString('en', {
            hour: 'numeric',
            minute: 'numeric',
          })}{' '}
          -{' '}
          {new Date(event.end_at).toLocaleTimeString('en', {
            hour: 'numeric',
            minute: 'numeric',
          })}
        </Text>
      </Table.Td>
      <Table.Td>
        <Checkbox
          disabled={updateEvent.isPending}
          checked={event.should_join_meeting}
          onChange={onToggle}
        />
      </Table.Td>
      <Table.Td>
        <Anchor component={Link} to={`/bots/${event.bot_id}`}>
          {event.bot_id}
        </Anchor>
      </Table.Td>
    </Table.Tr>
  );
}

function MicrosoftCalendarEvents({ id }: Readonly<{ id: string }>) {
  const now = useMemo(() => new Date(), []);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const events = useGetV1MicrosoftCalendarEvents({
    microsoft_calendar_id: id,
    start_after: now.toISOString(),
    start_before: new Date(now.getTime() + 90 * 24 * 60 * 60 * 1000).toISOString(),
    per_page: perPage,
  });

  if (events.isLoading) return <LoadingOverlay visible />;
  if (events.isError) return <h1>{events.error?.message}</h1>;
  if (!events.isSuccess) return <LoadingOverlay visible />;

  return (
    <div>
      <Group>
        <Button variant="subtle">Disconnect</Button>
        <Select
          placeholder="Pick value"
          data={[
            {
              value: '10',
              label: '10 per page',
            },
            {
              value: '25',
              label: '25 per page',
            },
            {
              value: '50',
              label: '50 per page',
            },
          ]}
          onChange={(value) => {
            if (value !== null) {
              setPerPage(Number.parseInt(value));
            }
          }}
          value={perPage.toString()}
          allowDeselect={false}
        />
        <Button.Group>
          <Button
            variant="default"
            onClick={() =>
              setPage((currentPage) => (currentPage > 1 ? currentPage - 1 : currentPage))
            }
          >
            Previous
          </Button>
          <Button variant="default" onClick={() => setPage(1)}>
            {page}
          </Button>
          <Button variant="default" onClick={() => setPage((currentPage) => currentPage + 1)}>
            Next
          </Button>
        </Button.Group>
      </Group>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Title</Table.Th>
            <Table.Th>Meeting</Table.Th>
            <Table.Th>Date & Time</Table.Th>
            <Table.Th>Add Skriver</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {events.data.body.items.map((event) => (
            <Table.Tr key={event.id}>
              <Table.Td>{event.title}</Table.Td>
              <Table.Td>{event.meeting_url}</Table.Td>
              <Table.Td>
                <Text size="lg">
                  {new Date(event.start_at).toLocaleDateString('en', {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short',
                  })}
                </Text>
                <Text size="xs">
                  {new Date(event.start_at).toLocaleTimeString('en', {
                    hour: 'numeric',
                    minute: 'numeric',
                  })}{' '}
                  -{' '}
                  {new Date(event.end_at).toLocaleTimeString('en', {
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </Text>
              </Table.Td>
              <Table.Td>
                <Checkbox checked={event.should_join_meeting} />
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </div>
  );
}
