import {Route, Routes} from 'react-router';
import {List} from './List';
import {View} from './View';

export function Transcripts() {
  return (
    <Routes>
      <Route path="" element={<List />} />
      <Route path=":transcriptId" element={<View />} />
    </Routes>
  );
}
