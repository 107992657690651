/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Skriver
 * Skriver is your trusted companion for transcribing audio into text in a machine-friendly format.

# Features

- Transcribe audio files into text
- Join meetings and transcribe them
- Connect to calendars to join and transcribe meetings automatically

# Quick Start

## Step 1: Create a Skriver Account

Head to [auth.skriver.app](https://auth.skriver.app/signup) and register for a free account. You
will be redirected to [dash.skriver.app](https://dash.skriver.app).

## Step 2: Create your API Keys

Once logged int, click on your username and then "Organization API Keys".

> 🚧 Store the keys somewhere safe.
>
> Note that Skriver will make your API key available only once. Hence make sure to immediately copy
> and save the key in a safe and secure location.
>
> Your key is used to authenticate into our APIs and is hence highly sensitive. Please make sure to
> keep the keys in a secure environment. In the event of a compromise, you can immediately revoke
> key
> access from our dashboard and generate a new key.

## Step 3: Transcribe your first audio

You are now ready to query our APIs.

```curl
curl --request POST \
    --url 'https://api.skriver.app/v1/transcripts/remote' \
    --header 'Content-Type: application/json' \
    --header 'X-Api-Key: <API_KEY>' \
    --data '{
        "media_url": "https://assets.skriver.app/examples/interview_speech-analytics.wav",
        "level": "basic",
        "recorded_at": "2023-12-24T10:00:00.000Z",
        "metadata": { "foo": "bar" }
    }'
```

This endpoint will return a response like the following:

```json
{
    "id": "018e8550-9db8-764c-8c11-b8cd85727d9e",
    "level": "basic",
    "status": "queued",
    "monologues": null,
    "duration_in_seconds": null,
    "metadata": {
        "foo": "bar"
    },
    "speakers": null,
    "recorded_at": "2023-12-24T10:00:00Z",
    "created_at": "2024-03-28T13:47:17.838803Z"
}
```

Use the `id` to monitor the status of the transcription by calling the `GET` endpoint

```curl
curl --request GET \
     --url 'https://api.skriver.app/transcripts/018c00cf-076c-7c01-bcc1-dad2e8074bc8' \
     --header 'X-Api-Key: <API_KEY>'
```

You will get a response that contains the transcription text

```json
{
    "id": "018e8550-9db8-764c-8c11-b8cd85727d9e",
    "level": "basic",
    "status": "completed",
    "monologues": [
        {
            "start": 3.1999998,
            "end": 29.855,
            "speaker": 0,
            "text": "Some transcribed text here"
        }
    ],
    "duration_in_seconds": 33958.688,
    "metadata": {
        "foo": "bar"
    },
    "speakers": [
        "0"
    ],
    "recorded_at": "2023-12-24T10:00:00Z",
    "created_at": "2024-03-28T13:47:17.838803Z"
}
```

Congratulations! You have now converted an audio file into text.

## Step 4: Schedule a bot to join your meeting

You can now schedule a bot to join your meeting and transcribe it. Using the API key, you can create
a "bot"

```curl
curl --request GET \
    --url 'https://api.skriver.app/v1/bots/' \
    --header 'Content-Type: application/json' \
    --header 'X-Api-Key: <API_KEY>' \
    --data '{
        "bot_name": "C3-P0",
        "meeting_url": "https://meet.google.com/abc-defg-hij",
        "join_at": "2024-03-28T10:00:00Z",
        "metadata": { "foo": "bar" }
    }'
```

 * OpenAPI spec version: 0.3.1
 */
import {
  z as zod
} from 'zod';


/**
 * @summary List all transcripts
 */
export const getV1TranscriptsQueryBotIdDefault = null;export const getV1TranscriptsQueryCreatedAfterDefault = new Date("undefined");export const getV1TranscriptsQueryCreatedBeforeDefault = new Date("undefined");export const getV1TranscriptsQueryMaxDurationDefault = null;export const getV1TranscriptsQueryMetadataDefault = null;export const getV1TranscriptsQueryMinDurationDefault = null;export const getV1TranscriptsQueryAfterDefault = null;export const getV1TranscriptsQueryBeforeDefault = null;export const getV1TranscriptsQueryPerPageDefault = 10;
export const getV1TranscriptsQueryPerPageMax = 100;


export const getV1TranscriptsQueryParams = zod.object({
  "bot_id": zod.string().uuid().nullish().describe('ID of the bot that created this transcript'),
  "created_after": zod.date().nullish().describe('Only include transcripts created after this date'),
  "created_before": zod.date().nullish().describe('Only include transcripts created before this date'),
  "max_duration": zod.number().nullish().describe('Only include transcripts with a duration in seconds less than this'),
  "metadata": zod.any().nullish().describe('Only include transcripts with this metadata.\n\nThis is a JSON object with string keys and string or number values.\n\nExample: `metadata[key]=value&metadata[key2]=123`'),
  "min_duration": zod.number().nullish().describe('Only include transcripts with a duration in seconds greater than this'),
  "after": zod.string().uuid().nullish(),
  "before": zod.string().uuid().nullish(),
  "per_page": zod.number().min(1).max(getV1TranscriptsQueryPerPageMax).default(getV1TranscriptsQueryPerPageDefault).describe('The number of results to return per page')
})

export const getV1Transcripts200Response = zod.object({
  "end_cursor": zod.string().uuid().nullish(),
  "has_next_page": zod.boolean(),
  "has_previous_page": zod.boolean(),
  "items": zod.array(zod.object({
  "bot_id": zod.string().uuid().nullish().describe('ID of the Bot if the transcript was created by a bot'),
  "created_at": zod.date().describe('Timestamp of when the transcript was created'),
  "duration_in_seconds": zod.number().nullish().describe('Duration of the transcribed file in seconds'),
  "id": zod.string().uuid().describe('Unique identifier of the transcript'),
  "level": zod.enum(['basic']).describe('Basic transcription level').or(zod.enum(['premium']).describe('Premium transcription level')),
  "metadata": zod.any().nullish().describe('Metadata of the transcribed file'),
  "monologues": zod.array(zod.object({
  "end": zod.number().describe('Relative time that this monologue ended. In seconds.'),
  "speaker_id": zod.string().describe('Speaker of this monologue. It represents the index of the `speakers` list.'),
  "start": zod.number().describe('Relative time that this monologue started. In seconds.'),
  "text": zod.string().describe('Transcript text')
})).optional(),
  "recorded_at": zod.date().nullish().describe('Timestamp of when the transcript was created'),
  "speakers": zod.array(zod.object({
  "id": zod.string().describe('Speaker ID'),
  "name": zod.string().describe('Speaker name')
})).optional(),
  "status": zod.enum(['queued', 'pending', 'processing', 'completed', 'failed', 'canceled'])
}).describe('Transcript data')).describe('The items in the collection'),
  "start_cursor": zod.string().uuid().nullish()
})

/**
 * Upload a local file to be transcribed. The file should be sent as an application/octet-stream
request.

If your file is over 10MB, you will need to use
the [/remote](/docs#tag/Transcripts/paths/~1v1~1transcripts~1remote/post) endpoint.

 * @summary Transcribe a local file
 */
export const postV1TranscriptsLocalQueryMetadataDefault = null;export const postV1TranscriptsLocalQueryRecordedAtDefault = new Date("undefined");

export const postV1TranscriptsLocalQueryParams = zod.object({
  "level": zod.enum(['basic']).describe('Basic transcription level').or(zod.enum(['premium']).describe('Premium transcription level')).optional().describe('Set the transcription level'),
  "metadata": zod.any().nullish().describe('Metadata of the transcribed file.\n\nThis is a map of strings using the qs notation.\n\nExample: `metadata[key]=value&metadata[key2]=123`'),
  "recorded_at": zod.date().nullish().describe('Timestamp of when the transcript was created')
})

export const postV1TranscriptsLocal200Response = zod.object({
  "bot_id": zod.string().uuid().nullish().describe('ID of the Bot if the transcript was created by a bot'),
  "created_at": zod.date().describe('Timestamp of when the transcript was created'),
  "duration_in_seconds": zod.number().nullish().describe('Duration of the transcribed file in seconds'),
  "id": zod.string().uuid().describe('Unique identifier of the transcript'),
  "level": zod.enum(['basic']).describe('Basic transcription level').or(zod.enum(['premium']).describe('Premium transcription level')),
  "metadata": zod.any().nullish().describe('Metadata of the transcribed file'),
  "monologues": zod.array(zod.object({
  "end": zod.number().describe('Relative time that this monologue ended. In seconds.'),
  "speaker_id": zod.string().describe('Speaker of this monologue. It represents the index of the `speakers` list.'),
  "start": zod.number().describe('Relative time that this monologue started. In seconds.'),
  "text": zod.string().describe('Transcript text')
})).optional(),
  "recorded_at": zod.date().nullish().describe('Timestamp of when the transcript was created'),
  "speakers": zod.array(zod.object({
  "id": zod.string().describe('Speaker ID'),
  "name": zod.string().describe('Speaker name')
})).optional(),
  "status": zod.enum(['queued', 'pending', 'processing', 'completed', 'failed', 'canceled'])
}).describe('Transcript data')

/**
 * @summary Transcribe a remote file
 */
export const postV1TranscriptsRemoteBodyMetadataDefault = null;export const postV1TranscriptsRemoteBodyRecordedAtDefault = new Date("undefined");

export const postV1TranscriptsRemoteBody = zod.object({
  "level": zod.enum(['basic']).describe('Basic transcription level').or(zod.enum(['premium']).describe('Premium transcription level')).optional(),
  "media_url": zod.string().url().describe('Public URL of a file to transcribe. You may use Pre-signed URLs.'),
  "metadata": zod.any().nullish().describe('Metadata of the transcribed file.\n\nThis is a map of strings using the qs notation.\n\nExample: `{ \"foo\": \"bar\", \"baz\": 123 }`'),
  "recorded_at": zod.date().nullish().describe('Timestamp of when the transcript was created')
})

export const postV1TranscriptsRemote200Response = zod.object({
  "bot_id": zod.string().uuid().nullish().describe('ID of the Bot if the transcript was created by a bot'),
  "created_at": zod.date().describe('Timestamp of when the transcript was created'),
  "duration_in_seconds": zod.number().nullish().describe('Duration of the transcribed file in seconds'),
  "id": zod.string().uuid().describe('Unique identifier of the transcript'),
  "level": zod.enum(['basic']).describe('Basic transcription level').or(zod.enum(['premium']).describe('Premium transcription level')),
  "metadata": zod.any().nullish().describe('Metadata of the transcribed file'),
  "monologues": zod.array(zod.object({
  "end": zod.number().describe('Relative time that this monologue ended. In seconds.'),
  "speaker_id": zod.string().describe('Speaker of this monologue. It represents the index of the `speakers` list.'),
  "start": zod.number().describe('Relative time that this monologue started. In seconds.'),
  "text": zod.string().describe('Transcript text')
})).optional(),
  "recorded_at": zod.date().nullish().describe('Timestamp of when the transcript was created'),
  "speakers": zod.array(zod.object({
  "id": zod.string().describe('Speaker ID'),
  "name": zod.string().describe('Speaker name')
})).optional(),
  "status": zod.enum(['queued', 'pending', 'processing', 'completed', 'failed', 'canceled'])
}).describe('Transcript data')

/**
 * Import a transcript into the system
 * @summary Import a transcript
 */
export const postV1TranscriptsImportBodyMetadataDefault = null;export const postV1TranscriptsImportBodyRecordedAtDefault = new Date("undefined");

export const postV1TranscriptsImportBody = zod.object({
  "level": zod.enum(['basic']).describe('Basic transcription level').or(zod.enum(['premium']).describe('Premium transcription level')).optional(),
  "metadata": zod.any().nullish().describe('Metadata of the transcribed file.\n\nThis is a map of strings using the qs notation.\n\nExample: `{ \"foo\": \"bar\", \"baz\": 123 }`'),
  "monologues": zod.array(zod.object({
  "end": zod.number().describe('Relative time that this monologue ended. In seconds.'),
  "speaker_id": zod.string().describe('Speaker of this monologue. It represents the index of the `speakers` list.'),
  "start": zod.number().describe('Relative time that this monologue started. In seconds.'),
  "text": zod.string().describe('Transcript text')
})),
  "recorded_at": zod.date().nullish().describe('Timestamp of when the transcript was created'),
  "speakers": zod.array(zod.object({
  "id": zod.string().describe('Speaker ID'),
  "name": zod.string().describe('Speaker name')
})).optional()
})

export const postV1TranscriptsImport200Response = zod.object({
  "bot_id": zod.string().uuid().nullish().describe('ID of the Bot if the transcript was created by a bot'),
  "created_at": zod.date().describe('Timestamp of when the transcript was created'),
  "duration_in_seconds": zod.number().nullish().describe('Duration of the transcribed file in seconds'),
  "id": zod.string().uuid().describe('Unique identifier of the transcript'),
  "level": zod.enum(['basic']).describe('Basic transcription level').or(zod.enum(['premium']).describe('Premium transcription level')),
  "metadata": zod.any().nullish().describe('Metadata of the transcribed file'),
  "monologues": zod.array(zod.object({
  "end": zod.number().describe('Relative time that this monologue ended. In seconds.'),
  "speaker_id": zod.string().describe('Speaker of this monologue. It represents the index of the `speakers` list.'),
  "start": zod.number().describe('Relative time that this monologue started. In seconds.'),
  "text": zod.string().describe('Transcript text')
})).optional(),
  "recorded_at": zod.date().nullish().describe('Timestamp of when the transcript was created'),
  "speakers": zod.array(zod.object({
  "id": zod.string().describe('Speaker ID'),
  "name": zod.string().describe('Speaker name')
})).optional(),
  "status": zod.enum(['queued', 'pending', 'processing', 'completed', 'failed', 'canceled'])
}).describe('Transcript data')

/**
 * @summary Get a single transcript
 */
export const getV1TranscriptsIdParams = zod.object({
  "id": zod.string().uuid().describe('The ID of the transcript.')
})

export const getV1TranscriptsId200Response = zod.object({
  "bot_id": zod.string().uuid().nullish().describe('ID of the Bot if the transcript was created by a bot'),
  "created_at": zod.date().describe('Timestamp of when the transcript was created'),
  "duration_in_seconds": zod.number().nullish().describe('Duration of the transcribed file in seconds'),
  "id": zod.string().uuid().describe('Unique identifier of the transcript'),
  "level": zod.enum(['basic']).describe('Basic transcription level').or(zod.enum(['premium']).describe('Premium transcription level')),
  "metadata": zod.any().nullish().describe('Metadata of the transcribed file'),
  "monologues": zod.array(zod.object({
  "end": zod.number().describe('Relative time that this monologue ended. In seconds.'),
  "speaker_id": zod.string().describe('Speaker of this monologue. It represents the index of the `speakers` list.'),
  "start": zod.number().describe('Relative time that this monologue started. In seconds.'),
  "text": zod.string().describe('Transcript text')
})).optional(),
  "recorded_at": zod.date().nullish().describe('Timestamp of when the transcript was created'),
  "speakers": zod.array(zod.object({
  "id": zod.string().describe('Speaker ID'),
  "name": zod.string().describe('Speaker name')
})).optional(),
  "status": zod.enum(['queued', 'pending', 'processing', 'completed', 'failed', 'canceled'])
}).describe('Transcript data')

/**
 * @summary Update a transcript
 */
export const putV1TranscriptsIdParams = zod.object({
  "id": zod.string().uuid().describe('The ID of the transcript.')
})

export const putV1TranscriptsIdBody = zod.object({
  "metadata": zod.any().nullish().describe('Metadata of the transcribed file.\n\nThis is a JSON object with string keys and string or number values.\n\nExample: `{\"key\": \"value\", \"key2\": 123}`'),
  "recorded_at": zod.date().nullish().describe('Timestamp of when the transcript was created'),
  "speakers": zod.array(zod.object({
  "id": zod.string().describe('Speaker ID'),
  "name": zod.string().describe('Speaker name')
})).optional()
})

export const putV1TranscriptsId200Response = zod.object({
  "bot_id": zod.string().uuid().nullish().describe('ID of the Bot if the transcript was created by a bot'),
  "created_at": zod.date().describe('Timestamp of when the transcript was created'),
  "duration_in_seconds": zod.number().nullish().describe('Duration of the transcribed file in seconds'),
  "id": zod.string().uuid().describe('Unique identifier of the transcript'),
  "level": zod.enum(['basic']).describe('Basic transcription level').or(zod.enum(['premium']).describe('Premium transcription level')),
  "metadata": zod.any().nullish().describe('Metadata of the transcribed file'),
  "monologues": zod.array(zod.object({
  "end": zod.number().describe('Relative time that this monologue ended. In seconds.'),
  "speaker_id": zod.string().describe('Speaker of this monologue. It represents the index of the `speakers` list.'),
  "start": zod.number().describe('Relative time that this monologue started. In seconds.'),
  "text": zod.string().describe('Transcript text')
})).optional(),
  "recorded_at": zod.date().nullish().describe('Timestamp of when the transcript was created'),
  "speakers": zod.array(zod.object({
  "id": zod.string().describe('Speaker ID'),
  "name": zod.string().describe('Speaker name')
})).optional(),
  "status": zod.enum(['queued', 'pending', 'processing', 'completed', 'failed', 'canceled'])
}).describe('Transcript data')

/**
 * ## Usage

### Level 3

```json
{
    "method": "DELETE",
    "url": "/v1/transcripts/{id}"
}
```

For when deleting is your thing

 * @summary Delete a transcript
 */
export const deleteV1TranscriptsIdParams = zod.object({
  "id": zod.string().uuid().describe('The ID of the transcript.')
})

export const deleteV1TranscriptsId200Response = zod.object({
  "bot_id": zod.string().uuid().nullish().describe('ID of the Bot if the transcript was created by a bot'),
  "created_at": zod.date().describe('Timestamp of when the transcript was created'),
  "duration_in_seconds": zod.number().nullish().describe('Duration of the transcribed file in seconds'),
  "id": zod.string().uuid().describe('Unique identifier of the transcript'),
  "level": zod.enum(['basic']).describe('Basic transcription level').or(zod.enum(['premium']).describe('Premium transcription level')),
  "metadata": zod.any().nullish().describe('Metadata of the transcribed file'),
  "monologues": zod.array(zod.object({
  "end": zod.number().describe('Relative time that this monologue ended. In seconds.'),
  "speaker_id": zod.string().describe('Speaker of this monologue. It represents the index of the `speakers` list.'),
  "start": zod.number().describe('Relative time that this monologue started. In seconds.'),
  "text": zod.string().describe('Transcript text')
})).optional(),
  "recorded_at": zod.date().nullish().describe('Timestamp of when the transcript was created'),
  "speakers": zod.array(zod.object({
  "id": zod.string().describe('Speaker ID'),
  "name": zod.string().describe('Speaker name')
})).optional(),
  "status": zod.enum(['queued', 'pending', 'processing', 'completed', 'failed', 'canceled'])
}).describe('Transcript data')

